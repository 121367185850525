import styles from "./EmailDrawerAlert.module.scss";
import { EmailDeliveryResponse } from "shared/types/marketingMaterials";
import { Alert } from "antd";

type Props = {
  lastRun?: EmailDeliveryResponse;
};
export const EmailDrawerAlert = ({ lastRun }: Props) => {
  if (!lastRun || lastRun.status === "failed") return null;

  if (lastRun.status === "rejected") {
    return (
      <Alert
        type="error"
        showIcon
        className={styles.alert}
        message={
          <div className={styles["alert-cols"]}>
            <span>There was an error sending this marketing material.</span>
            <span>Please try again.</span>
          </div>
        }
      />
    );
  }

  return (
    <Alert
      type="info"
      showIcon
      className={styles.alert}
      message={
        <div className={styles["alert-cols"]}>
          <span>
            The recipients below couldn&apos;t be found and were unable to
            receive the email.
          </span>
          <span>
            The email was successfully sent to all other recipients (
            {lastRun.validRecipients.length}).`
          </span>
        </div>
      }
    />
  );
};
