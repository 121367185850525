import { Drawer, Typography, Tag, message } from "antd";
import {
  FileZipOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import styles from "./EmailDrawer.module.scss";
import EmailDrawerFooter from "./EmailDrawerFooter";
import { Invoice } from "shared/types/salesEnablement";
import { useInvoice } from "../hooks/useInvoice";
import { useUser } from "shared/hooks/useUser";
import { format } from "date-fns";

const FileName = ({
  prefix,
  date,
  extension,
}: {
  prefix: string;
  date: string;
  extension: string;
}) => {
  return (
    <Typography.Text>
      {prefix}-{format(new Date(date), "MMMM_yyyy")}.{extension}
    </Typography.Text>
  );
};

const EmailDrawer = ({
  data,
  isEmailDrawerVisible,
  closeEmailDrawer,
}: {
  data: Invoice[];
  isEmailDrawerVisible: boolean;
  closeEmailDrawer: () => void;
}) => {
  const { email } = useUser();
  const { useEmailInvoice } = useInvoice();
  const { mutate: emailInvoice, isLoading } = useEmailInvoice();

  const handleSendEmail = async () => {
    if (!data || data.length === 0) {
      message.error("No invoices available to send");
      return;
    }

    emailInvoice(
      { invoices: data, emailIds: [email] },
      {
        onSuccess: () => {
          message.success("Email sent successfully!");
          closeEmailDrawer();
        },
        onError: (error: any) => {
          message.error(
            `Failed to send email: ${error.message || "Unknown error"}`,
          );
        },
      },
    );
  };

  return (
    <Drawer
      title={<b>Email</b>}
      width={528}
      visible={isEmailDrawerVisible}
      onClose={closeEmailDrawer}
      closable={false}
      destroyOnClose
      footer={
        <EmailDrawerFooter
          onClose={closeEmailDrawer}
          sendEmail={handleSendEmail}
          isLoading={isLoading}
        />
      }
    >
      <div className={styles.emailContainer}>
        <div className={styles.destinationEmail}>
          <div>
            <Typography.Text strong>Destination Email:</Typography.Text>
          </div>
          <div>
            <Tag>{email}</Tag>
          </div>
        </div>
        <div className={styles.fileTitle}>
          <Typography.Text strong>File(s) being sent:</Typography.Text>
        </div>
        {data.map(item => (
          <div key={item.id} className={styles.fileItem}>
            <div className={styles.zipFile}>
              <FileZipOutlined />
              <FileName prefix="Invoice" date={item.postDate} extension="zip" />
            </div>
            <div className={styles.pdfFile}>
              <FilePdfOutlined />
              <FileName prefix="Invoice" date={item.postDate} extension="pdf" />
            </div>
            <div className={styles.csvFile}>
              <FileExcelOutlined />
              <FileName prefix="Invoice" date={item.postDate} extension="csv" />
            </div>
            <div className={styles.csvFile}>
              <FileExcelOutlined />
              <FileName
                prefix="Communicorp_Order_Detail"
                date={item.postDate}
                extension="csv"
              />
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default EmailDrawer;
