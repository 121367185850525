import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Row,
  Space,
  Typography,
} from "antd";
import { sum } from "lodash";
import { useMemo } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { ROUTES as ARCHIVE_ROUTES } from "screens/designStudio/archive/constants";
import { useDeliveryMarketingMaterialByPrint } from "shared/hooks/adLibrary/marketingMaterials";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import {
  MarketingMaterialPrintDeliveryForm,
  MarketingMaterialPrintOrderInput,
} from "shared/types/marketingMaterials";
import { useMarketingMaterialDeliveryData } from "../hooks/useMarketingMaterialDeliveryData";
import { ROUTES } from "../utils/constants";
import styles from "./PrintCheckout.module.scss";
import { RateTable } from "./printCheckout/RateTable";
import { ShippingDataFields } from "./printCheckout/ShippingDataFields";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

const printCheckoutFormId = "printCheckoutForm";

export const PrintCheckout = ({
  form,
}: {
  form: FormInstance<MarketingMaterialPrintDeliveryForm>;
}) => {
  const deliveryData = useMarketingMaterialDeliveryData("print");
  const navigateWithSearch = useNavigateWithSearch();
  const { mutate: deliveryMarketingMaterialByPrint, isLoading } =
    useDeliveryMarketingMaterialByPrint();
  const location = useLocation();
  const { materialId } = useParams();
  const { orderId } = useParams();

  const onClose = () => {
    if (orderId) {
      navigateWithSearch(ARCHIVE_ROUTES.printArchive);
    } else {
      navigateWithSearch(ROUTES.print(materialId ?? ""));
    }
    form.resetFields(["shippingData"]);
  };

  const onSubmit = () => {
    const formData = form.getFieldsValue(true);
    const materialPrintOrderData = deliveryData.map(deliveryDataItem => {
      const materialOrderData =
        formData.materials[deliveryDataItem.material.id];
      const weight = materialOrderData?.weight ?? 0;
      return {
        printOrderData: {
          materialId: materialOrderData.id,
          printOption: materialOrderData.printOption,
          copies: materialOrderData.copies,
          name: materialOrderData.name,
          printMode: materialOrderData.printMode,
          amount: materialOrderData.amount,
          totalWeight: materialOrderData.copies * weight,
        },
        deliveryMethod: "print" as const,
        material: deliveryDataItem.material,
        template: deliveryDataItem.template,
        variables: deliveryDataItem.variables,
        renderVariables: deliveryDataItem.renderVariables,
      };
    });

    const printDeliveryData: MarketingMaterialPrintOrderInput = {
      shippingData: formData.shippingData,
      deliveryMethod: "print",
      materialPrintOrderData,
    };
    deliveryMarketingMaterialByPrint(printDeliveryData, {
      onSuccess: () => {
        successNotification({
          messageLabel:
            "Your print order has been successfully sent. You'll receive a confirmation email shortly.",
          size: "big",
        });
        onClose();
      },
      onError: () => {
        errorNotification({
          messageLabel:
            "An error occurred while placing your print order. Please try again later.",
          size: "big",
        });
        onClose();
      },
    });
  };

  const isVisible = useMemo(() => {
    if (orderId) {
      return !!matchPath(
        { path: ARCHIVE_ROUTES.printOrderDetails(orderId) },
        location.pathname,
      );
    }
    return !!matchPath(
      { path: ROUTES.printCheckout(materialId) },
      location.pathname,
    );
  }, [orderId, location.pathname, materialId]);

  const totalWeight = useMemo(() => {
    const formData: MarketingMaterialPrintDeliveryForm =
      form.getFieldsValue(true);
    const { materials } = formData;
    if (!materials) return 0;
    return sum(
      Object.values(materials).map(
        material => material?.printOption?.weight ?? 0,
      ),
    );
  }, [form]);

  return (
    <Drawer
      title="Order Prints"
      width="70%"
      visible={isVisible}
      onClose={onClose}
      className={styles.drawer}
      destroyOnClose
      footerStyle={{ textAlign: "right" }}
      bodyStyle={{ padding: 0 }}
      footer={
        <Space className={styles.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            form={printCheckoutFormId}
            disabled={!!orderId}
            onClick={onSubmit}
            loading={isLoading}
          >
            Place Order
          </Button>
        </Space>
      }
    >
      <Row gutter={48} className={styles.row}>
        <Col span={12}>
          <Form.Item name="shippingData" noStyle>
            <ShippingDataFields
              disabled={!!orderId}
              totalWeight={totalWeight}
            />
          </Form.Item>
        </Col>
        <Col span={12} className={styles.summaryCol}>
          <Form.Item shouldUpdate className={styles.summary}>
            {() => {
              const formData = form.getFieldsValue(true);
              const { materials, shippingData } = formData;
              if (!materials || !shippingData) {
                return null;
              }
              return (
                <>
                  <Typography.Title level={5}>Order Summary</Typography.Title>
                  <RateTable
                    materials={materials}
                    shippingMethod={shippingData.shippingMethod}
                    form={form}
                  />
                </>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Drawer>
  );
};
