import { useQuery } from "react-query";
import API from "services";
import { errorNotification } from "shared/components/customNotification/Notification";
import { useDataList } from "shared/components/dataList/useDataList";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { useFeedContext } from "../../shared/contexts/FeedContext";

interface ValidateAssetNamesArgs {
  feedId: string;
  search: string;
  filters: IFilter[];
  assetBatchIds: string[];
  selectedRows: string[];
  selectAll: boolean;
  page: string;
}

const validateAssetNames = async ({
  feedId,
  search,
  filters,
  assetBatchIds,
  selectedRows,
  selectAll,
  page,
}: ValidateAssetNamesArgs) => {
  try {
    const { result, error } =
      await API.services.assetExporter.validateAssetNames({
        feedId,
        search,
        filters,
        assetBatchIds,
        selectedRows,
        selectAll,
        page,
        pageSize: "30",
      });

    if (error) {
      throw new Error(error.message);
    }

    return result;
  } catch (error) {
    errorNotification({
      messageLabel: `An error occurred while validating asset names`,
    });
    throw error;
  }
};

export const useValidateAssetNames = () => {
  const [dataList] = useDataList();
  const {
    feedId,
    filterArgs: filters,
    reqSearchValue: search,
    selectedAssetBatches,
    selectedRows,
    page,
  } = useFeedContext();

  return useQuery(
    ["assetCount", feedId, selectedAssetBatches.length],
    () =>
      validateAssetNames({
        feedId,
        search,
        filters: filters ?? [],
        assetBatchIds: selectedAssetBatches,
        selectedRows,
        selectAll: dataList.selectAll,
        page: String(page),
      }),
    {
      enabled: !!feedId,
      cacheTime: 0,
    },
  );
};
