import {
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useMemo } from "react";
import { useDataListURLContext } from "shared/components/dataListURL/dataListURLContext";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { useIsPrintEnabled } from "shared/hooks/salesEnablement/useIsPrintEnabled";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import { MaterialStatus, TemplateStatus } from "shared/types/salesEnablement";
import { useMarketingMaterialActions } from "./hooks/useMarketingMaterialActions";
import { ROUTES, disabledPrintDeliveryMessage } from "./utils/constants";

type Props = {
  isPdfDownloading: boolean;
  selectedItems: MarketingMaterialTableItem[];
  getSendByEmailStatus: (selectedItems: MarketingMaterialTableItem[]) => {
    isSendByEmailDisabled: boolean;
    sendByEmailTooltip: string;
  };
  onSendByEmail: (selectedItems: MarketingMaterialTableItem[]) => void;
  getPdfDeliveryData: (selectedItems: MarketingMaterialTableItem[]) => void;
  onPrint: (selectedItems: MarketingMaterialTableItem[]) => void;
};

const localStorageLayoutKey = "MarketingMaterialsLayout";

export const Toolbar = ({
  isPdfDownloading,
  selectedItems,
  getSendByEmailStatus,
  getPdfDeliveryData,
  onSendByEmail,
  onPrint,
}: Props) => {
  const isAdmin = useIsSalesEnablementAdmin();
  const [layout, setLayout] = useLayoutPreference(localStorageLayoutKey);
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  const { setSelectedIds } = useDataListURLContext();
  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    keyof MarketingMaterialTableItem,
    MarketingMaterialTableItem
  >();
  const { onDelete, onDuplicate } = useMarketingMaterialActions();
  const navigateWithSearch = useNavigateWithSearch();

  const { isSendByEmailDisabled, sendByEmailTooltip } = useMemo(
    () => getSendByEmailStatus(selectedItems),
    [selectedItems, getSendByEmailStatus],
  );

  const isPrintEnabled = useIsPrintEnabled();

  const toolbarContents: ToolbarButton = {
    Edit: {
      disabled: selectedItems.length !== 1,
      icon: <EditOutlined />,
      onClick: () => {
        const [item] = selectedItems;
        navigateWithSearch(`${ROUTES.edit(item.id)}`);
      },
    },
    Duplicate: {
      disabled:
        selectedItems.length !== 1 || !!selectedItems[0]?.templateErrorStatus,
      icon: <CopyOutlined />,
      onClick: () => {
        const selectedMaterial = selectedItems[0];
        if (!selectedMaterial) return;
        onDuplicate({
          material: selectedMaterial,
          mode: "optimistic",
        });
      },
      extraInfo: {
        tooltip:
          selectedItems.length !== 1
            ? "Only one material can be duplicated at a time"
            : selectedItems[0]?.templateErrorStatus?.message,
      },
    },
    New: {
      onClick: () => {
        navigateWithSearch("new");
      },
      extraInfo: {
        text: "New Marketing Material",
        tooltip: "New Marketing Material",
      },
    },
    Delete: {
      disabled: false,
      icon: <EditOutlined />,
      onClick: () => {
        onDelete({
          ids: selectedItems.map(item => item.id),
          onSuccess: () => setSelectedIds([]),
          mode: "optimistic",
        });
      },
    },
    DownloadPDF: {
      disabled:
        isPdfDownloading ||
        !selectedItems.length ||
        selectedItems.some(
          item =>
            !item.templateDeliveryMethods?.includes("download") ||
            item.templateStatus !== TemplateStatus.PUBLISHED ||
            item.materialStatus !== MaterialStatus.READY,
        ),

      onClick: () => {
        if (!selectedItems.length) return;
        getPdfDeliveryData(selectedItems);
      },
      extraInfo: {
        title: "Download",
        tooltipText: selectedItems.some(
          item => item.materialStatus !== MaterialStatus.READY,
        )
          ? 'One or more of the selected materials do not have the status "Ready to Deliver" and are unable to be downloaded.'
          : "Download",
        icon: <DownloadOutlined />,
      },
    },
    Print: {
      disabled:
        !selectedItems.length ||
        !isPrintEnabled ||
        selectedItems.some(
          item => !!item.templateDeliveryMethods?.includes("email"),
        ),
      onClick: () => {
        onPrint(selectedItems);
      },
      extraInfo: {
        title: "Order Prints",
        tooltipText:
          isPrintEnabled && selectedItems.length
            ? "Print"
            : disabledPrintDeliveryMessage,
        icon: <PrinterOutlined />,
      },
    },
    CustomButton: {
      disabled: isSendByEmailDisabled || !isAdmin,
      onClick: () => {
        onSendByEmail(selectedItems);
      },
      extraInfo: {
        text: "Email",
        title: "Email",
        tooltipText: sendByEmailTooltip,
        icon: <MailOutlined />,
      },
    },
  };

  return (
    <ToolbarTable
      layout={layout}
      titleTooltip="Search"
      searchPlaceholder="Search"
      toolbarContents={toolbarContents}
      onSearch={setGlobalFilter}
      searchValue={globalFilter ?? undefined}
      disableSearch={false}
      onClickLayout={setLayout}
      previewFormat={false}
      sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
      onSortChange={([columnKey, order]) => {
        sortItems(columnKey, order);
      }}
      includeFilterOnTableLayout={true}
    />
  );
};
