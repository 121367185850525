import { EditOutlined } from "@ant-design/icons";
import { Col, FormInstance, Row, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Thumbnail } from "screens/homepage/Thumbnail";
import {
  FedexShippingMethod,
  MarketingMaterialPrintDeliveryRateTable,
  PrintDeliveryMaterialItem,
} from "shared/types/marketingMaterials";
import { salesEnablementTheme } from "theme/salesEnablement";
import CopiesField from "../printForm/CopiesField";
import { taxRate } from "../utils/constants";
import { getPriceByQuantity } from "../utils/printDelivery.utils";
import styles from "./RateTable.module.scss";

export const RateTable = ({
  materials,
  form,
  shippingMethod,
}: {
  materials: Record<string, PrintDeliveryMaterialItem>;
  shippingMethod?: FedexShippingMethod;
  form: FormInstance;
}) => {
  const [editingCopiesCellId, setEditingCopiesCellId] = useState<string>();
  const { orderId } = useParams();

  const onEditCopies = (id: string, copies: number) => {
    const formValues = form.getFieldsValue();
    const material = materials[id];
    if (!material) return;
    const totalAmount = getPriceByQuantity(
      copies,
      material.printOption,
      material.printMode,
    );
    form.setFieldsValue({
      ...formValues,
      materials: {
        ...materials,
        [id]: {
          ...materials[id],
          copies,
          totalAmount,
        },
      },
    });
  };
  const columns: ColumnsType<MarketingMaterialPrintDeliveryRateTable> = [
    {
      title: "",
      dataIndex: "type",
      key: "type",
      width: 270,
      className: styles.leftColumn,
    },
    {
      title: "Copies",
      dataIndex: "copies",
      key: "copies",
      className: styles.simpleColumn,
      width: "10%",
      render: (_, record) => {
        if (!("copies" in record)) return;
        if (record.id && editingCopiesCellId === record.id) {
          const material = materials[record.id];
          return (
            <CopiesField
              printOption={material?.printOption}
              value={Number(record.copies)}
              onChange={value => onEditCopies(record?.id ?? "", Number(value))}
              onBlur={() => setEditingCopiesCellId(undefined)}
              onPressEnter={() => setEditingCopiesCellId(undefined)}
            />
          );
        }
        return (
          <>
            {!orderId && (
              <EditOutlined
                className={styles.editIcon}
                style={{
                  color: salesEnablementTheme?.primaryColor,
                }}
                onClick={() => setEditingCopiesCellId(record.id)}
              />
            )}
            <Typography.Text>{record.copies}</Typography.Text>
          </>
        );
      },
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      width: "10%",
      className: styles.simpleColumn,
      render: (_, record) => {
        return <Typography.Text>${record.subtotal}</Typography.Text>;
      },
    },
  ];

  const priceData: MarketingMaterialPrintDeliveryRateTable[] = useMemo(() => {
    const allMaterials = Object.values(materials);
    const items = allMaterials.length;
    const allMaterialData = allMaterials.map(material => ({
      id: material.id,
      type: (
        <Row gutter={12} align="middle" justify="start">
          <Col>
            <Thumbnail thumbnail={material.templateThumbnail ?? ""} />
          </Col>
          <Col>
            <Typography.Text className={styles.materialName}>
              {material.name}
            </Typography.Text>
            <br />
            <Typography.Text className={styles.materialPrintOption}>
              {material.printMode}
            </Typography.Text>
          </Col>
        </Row>
      ),
      copies: material.copies,
      subtotal: material.amount.toFixed(2),
    }));
    const allMaterialsSubtotal = allMaterialData.reduce((acc, { subtotal }) => {
      return acc + Number(subtotal);
    }, 0);
    const shipping = shippingMethod?.totalCharge ?? 0;
    const totalBeforeTaxes = allMaterialsSubtotal + shipping;
    const taxes = totalBeforeTaxes * taxRate;
    const grandTotal = totalBeforeTaxes + taxes;
    return [
      ...allMaterialData,
      {
        type: `Items (${items})`,
        subtotal: allMaterialsSubtotal.toFixed(2),
      },
      { type: "Shipping", subtotal: shipping.toFixed(2) },
      { type: "Total before taxes", subtotal: totalBeforeTaxes.toFixed(2) },
      { type: "Taxes", subtotal: taxes.toFixed(2) },
      { type: "Grand Total", subtotal: grandTotal.toFixed(2) },
    ];
  }, [materials, shippingMethod]);

  return (
    <>
      <Table
        className={styles.table}
        rowClassName={record =>
          record.type === "Grand Total" ? styles.grandTotalRow : ""
        }
        dataSource={priceData}
        columns={columns}
        bordered={false}
        pagination={false}
      />
      <Typography.Text className={styles.helpText}>
        Payment instructions will be provided by email
      </Typography.Text>
    </>
  );
};
