import React from "react";
import { InputNumber, Row, Col } from "antd";

type RangeFilterProps = {
  title: string;
  value?: [string | null, string | null];
  onChange: (value: [string | null, string | null]) => void;
};

export const RangeFilter: React.FC<RangeFilterProps> = ({
  title,
  value = [null, null],
  onChange,
}) => {
  const [minStr, maxStr] = value;

  const minNum = minStr !== null ? Number(minStr) : undefined;
  const maxNum = maxStr !== null ? Number(maxStr) : undefined;

  const handleChange = (type: "min" | "max", newValue: number | undefined) => {
    let strValue: string | null = null;
    if (newValue !== undefined && !isNaN(newValue)) {
      strValue = String(newValue);
    }

    if (type === "min") {
      onChange([strValue, maxStr]);
    } else {
      onChange([minStr, strValue]);
    }
  };

  const parser = (val: string | undefined) => {
    if (!val) return NaN;
    const parsed = parseFloat(val.replace(/\$\s?|(,*)/g, ""));
    return isNaN(parsed) ? NaN : parsed;
  };

  return (
    <div>
      <label>{title}</label>
      <Row gutter={8}>
        <Col span={12}>
          <InputNumber
            formatter={val => (val ? `$ ${val}` : "")}
            parser={parser}
            placeholder="Min"
            value={minNum}
            onChange={val => handleChange("min", val)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <InputNumber
            formatter={val => (val ? `$ ${val}` : "")}
            parser={parser}
            placeholder="Max"
            value={maxNum}
            onChange={val => handleChange("max", val)}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </div>
  );
};
