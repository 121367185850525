import { Drawer } from "antd";
import { useState } from "react";
import SummaryDrawerFooter from "./SummaryDrawerFooter";
import { useParams } from "react-router-dom";
import EmailDrawer from "./EmailDrawer";
import PDFPreview from "screens/designStudio/shared/preview/PDFPreview";
import { useInvoice } from "../hooks/useInvoice";
import { downloadInvoices } from "../utils";
import styles from "./SummaryDrawer.module.scss";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";

const InvoiceSummaryDrawer = () => {
  const { invoiceId } = useParams();
  const [isEmailDrawerVisible, setIsEmailDrawerVisible] = useState(false);
  const navigateWithSearch = useNavigateWithSearch();
  const { useFetchInvoiceById } = useInvoice();
  const { data, isLoading, isError } = useFetchInvoiceById(invoiceId ?? "");

  const onVisibleChange = (visible: boolean) => {
    if (!visible) {
      navigateWithSearch("/sales-enablement/invoices");
    }
  };

  const onSendEmail = () => {
    setIsEmailDrawerVisible(true);
  };

  const closeEmailDrawer = () => {
    setIsEmailDrawerVisible(false);
  };

  return (
    <>
      <Drawer
        title={<b>Invoice Summary</b>}
        width={928}
        visible={!!invoiceId}
        onClose={() => navigateWithSearch("/sales-enablement/invoices")}
        afterVisibleChange={onVisibleChange}
        closable={false}
        footer={
          invoiceId && (
            <SummaryDrawerFooter
              onClose={() => navigateWithSearch("/sales-enablement/invoices")}
              onSendEmail={onSendEmail}
              onDownload={() => data && downloadInvoices(data)}
            />
          )
        }
      >
        {invoiceId && (
          <div className={styles.container}>
            <div className={styles.preview}>
              <PDFPreview
                isLoading={isLoading}
                src={data?.pdfUrl}
                isError={isError}
              />
            </div>
          </div>
        )}
      </Drawer>
      {invoiceId && (
        <EmailDrawer
          data={data ? [data] : []}
          isEmailDrawerVisible={isEmailDrawerVisible}
          closeEmailDrawer={closeEmailDrawer}
        />
      )}
    </>
  );
};

export default InvoiceSummaryDrawer;
