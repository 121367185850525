import { useQuery } from "react-query";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import API from "services";
import { getResultIfOk } from "services/adLibrary";
import { PrintOrdersArchives } from "shared/types/salesEnablement/order";

export const useFetchPrintOrdersArchives = () => {
  return useQuery<PrintOrdersArchives>({
    queryKey: [QUERY_KEYS.printArchives],
    queryFn: async () => {
      const { result } = getResultIfOk(
        await API.services.salesEnablement.getPrintOrdersArchives(),
      );
      return result;
    },
  });
};
