import { Invoice } from "shared/types/salesEnablement";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { fields } from "../fields";
import NameCardCell from "shared/components/NameCardCell";
import { format } from "date-fns";

export const generateInvoiceColumns = (
  navigateWithSearch: (to: string) => void,
): ITableColumnURL<keyof Invoice, Invoice>[] => {
  return [
    {
      title: "Invoice No.",
      dataIndex: "id",
      key: "id",
      width: 180,
      sorter: fields.id?.sorterFn,
      render: (id: string) => (
        <NameCardCell
          name={id}
          disableThumbnail={true}
          onCellClick={() =>
            navigateWithSearch(`/sales-enablement/invoices/${id}`)
          }
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      sorter: fields.amount?.sorterFn,
      render: (amount: number) => <span>${amount.toFixed(2)}</span>,
    },
    {
      title: "Doc. Date",
      dataIndex: "postDate",
      key: "postDate",
      width: 150,
      sorter: fields.postDate?.sorterFn,
      render: (date: string) => (
        <span>{format(new Date(date), "MM/yyyy")}</span>
      ),
    },
  ];
};
