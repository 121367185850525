import { useQuery } from "react-query";
import API from "services";
import { getResultIfOk } from "services/adLibrary";
import { MarketingMaterialPrintDeliveryOrder } from "shared/types/salesEnablement/order";

export const useFetchPrintOrders = () => {
  return useQuery<MarketingMaterialPrintDeliveryOrder[] | undefined, Error>({
    queryKey: ["printOrders"],
    queryFn: async () => {
      const { result } = getResultIfOk(
        await API.services.salesEnablement.getPrintOrders(),
      );
      return result;
    },
  });
};
