import { useNavigate, useParams } from "react-router-dom";
import { Drawer, Tabs } from "antd";
import { ROUTES } from "./utils/constants";
import { EmailDrawerFooter } from "./emailDelivery/EmailDrawerFooter";
import styles from "./EmailDelivery.module.scss";
import { useDeliveryMarketingMaterial } from "shared/hooks/adLibrary/marketingMaterials";
import { useForm } from "antd/lib/form/Form";
import { EmailDrawerContent } from "./emailDelivery/EmailDrawerContent";
import { useCallback, useEffect, useState } from "react";
import EllipsisText from "shared/components/card/EllipsisText";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { findIndex } from "lodash";
import { useMarketingMaterialDeliveryData } from "./hooks/useMarketingMaterialDeliveryData";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import {
  EmailDeliveryInput,
  EmailDeliveryResponse,
} from "shared/types/marketingMaterials";

type Props = {
  calledBy: "DRAWER" | "TABLE";
};

export const EmailDelivery = ({ calledBy }: Props) => {
  const isAdmin = useIsSalesEnablementAdmin();
  const navigate = useNavigate();
  const [form] = useForm();
  const deliveryData = useMarketingMaterialDeliveryData("email");
  const [data, setData] = useState<EmailDeliveryInput[]>(deliveryData);
  const [lastRun, setLastRun] = useState<Record<
    string,
    EmailDeliveryResponse
  > | null>(null);

  const [activeTab, setActiveTab] = useState<string>(data?.[0]?.material.id);

  useEffect(() => {
    if (data?.length && typeof activeTab === "undefined") {
      setActiveTab(data?.[0]?.material.id);
    }
  }, [data, activeTab]);

  const { mutateAsync: delivery, isLoading } = useDeliveryMarketingMaterial();
  const fromTemplate = location.pathname.includes("templates/");

  const { materialId, templateId } = useParams<{
    materialId: string;
    templateId: string;
  }>();

  const notification = useCallback(
    (error?: "fixErrors" | "failed") => {
      if (error === "fixErrors") {
        return errorNotification({
          messageLabel: "Please fix all errors before continuing.",
          size: "big",
        });
      }
      if (error === "failed") {
        return errorNotification({
          messageLabel: `Your email${
            data.length > 1 ? "s " : " "
          } could not be sent. Please check the recipient list`,
          size: "big",
        });
      }
      return successNotification({
        messageLabel: `Your email${
          data.length > 1 ? "s have" : " has"
        } been successfully sent.`,
        size: "big",
      });
    },
    [data],
  );

  const onClose = () => {
    const path =
      calledBy === "DRAWER" && materialId
        ? ROUTES.edit(materialId)
        : ROUTES.home;
    fromTemplate
      ? navigate(
          `/sales-enablement/templates/${templateId}/material/${materialId}`,
        )
      : navigate(`${path}${location.search}`);
  };

  const validateFields = async () => {
    try {
      await form.validateFields([
        [activeTab, "from"],
        [activeTab, "to"],
        [activeTab, "subject"],
      ]);
      return true;
    } catch (err) {
      return false;
    }
  };

  const next = async () => {
    const isValid = await validateFields();
    if (!isValid) return notification("fixErrors");

    const currIdx = findIndex(data, item => item.material.id === activeTab);

    setActiveTab(data[currIdx + 1].material.id);
  };

  const onSend = async () => {
    try {
      const values = form?.getFieldsValue();
      await form.validateFields();

      const results = await Promise.all(
        data.map(async ({ template, material, renderVariables }) => {
          return {
            id: material.id,
            ...(await delivery({
              deliveryMethod: "email",
              recipients: values[material.id].to as string[],
              subject: values[material.id].subject,
              template,
              material,
              renderVariables,
            })),
          };
        }),
      );

      const fails = results.filter(r => r.status !== "success");

      if (!fails.length) {
        notification();
        onClose();
        return;
      }

      const newTab = data.find(m => fails.some(f => f.id === m.material.id))
        ?.material?.id;

      setData(prev =>
        prev.filter(m => fails.some(f => f.id === m.material.id)),
      );

      if (newTab) setActiveTab(newTab);

      fails.forEach(async fail => {
        const { id, ...res } = fail;

        if (res.status === "success") return;

        setLastRun(prev => ({ ...prev, [id]: res }));

        if (res.status === "rejected") return;

        form.setFieldsValue({ [id]: { to: res.invalidRecipients } });

        form.setFields([
          {
            name: [id, "to"],
            errors: [
              `The addresses entered above are invalid and couldn't receive the email. Please confirm if they are correct.`,
            ],
          },
        ]);
      });
    } catch (err) {
      notification("fixErrors");
    }
  };

  const nextHandler =
    activeTab === data?.[data.length - 1]?.material.id ? onSend : next;

  if (!isAdmin) return null;

  return (
    <Drawer
      title={<b>Email</b>}
      width="928px"
      visible={true}
      onClose={onClose}
      destroyOnClose
      className={styles.drawer}
      closeIcon={null}
      bodyStyle={{
        paddingBottom: 0,
        ...(data?.length > 1 && { paddingTop: 0 }),
      }}
      footer={
        <EmailDrawerFooter
          onClose={onClose}
          onSend={nextHandler}
          isSaving={isLoading}
          isLastTab={activeTab === data?.[data.length - 1]?.material.id}
        />
      }
    >
      {data.length > 1 ? (
        <Tabs
          className={styles.tabs}
          tabPosition={"top"}
          defaultActiveKey={data?.[0]?.material.id}
          activeKey={activeTab}
          onChange={async activeKey => {
            const isValid = await validateFields();
            if (isValid) return setActiveTab(activeKey);
            notification("fixErrors");
          }}
        >
          {data.map(({ material, template }, i) => (
            <Tabs.TabPane
              className={styles.tabPane}
              tab={
                <EllipsisText
                  text={`${i + 1}. ${material.name}`}
                  charDisplayLimit={25}
                />
              }
              key={material.id}
            >
              <EmailDrawerContent
                lastRun={lastRun?.[material.id]}
                form={form}
                material={material}
                template={template}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : data?.length ? (
        <EmailDrawerContent
          form={form}
          {...data[0]}
          lastRun={lastRun?.[data[0]?.material?.id]}
        />
      ) : null}
    </Drawer>
  );
};
