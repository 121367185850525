import { Button, Form, FormItemProps, Input } from "antd";
import { useCallback, useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  specialStateLicensePattern,
  specialStateMessage,
} from "shared/constants/salesEnablement";
import { isSpecialState } from "../hooks/agentManagement";
import { StateKey, states } from "shared/constants/states";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";

type Props = {
  fieldName: string[];
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const LicenseField = ({ fieldName, label, onChange }: Props) => {
  const [isInputVisible, setIsInputVisible] = useState(true);
  const form = useMaterialFormInstance();
  const locations: StateKey[] = form.getFieldValue("locations") ?? [];
  const specialStates = locations.filter(isSpecialState);
  const selectedStatesMessage = specialStates
    ?.map(stateId => specialStateMessage[stateId])
    .join(" / ");

  const helpMessage = `Per ${specialStates.join(
    "/",
  )} DOI, please enter this full text before your number: ${selectedStatesMessage}`;

  const getPattern = useCallback(() => {
    if (specialStates.length === 1) {
      return new RegExp(`^${specialStateLicensePattern[specialStates[0]]}$`);
    } else if (specialStates.length > 1) {
      const arPattern = specialStateLicensePattern[states.AR.id];
      const caPattern = specialStateLicensePattern[states.CA.id];
      return new RegExp(
        `^(?:(${arPattern}) \\/ (${caPattern})$|(${caPattern}) \\/ (${arPattern})$)`,
      );
    }
  }, [specialStates]);

  const formProps: FormItemProps = useMemo(() => {
    return {
      name: fieldName,
      label: specialStates.length
        ? specialStates.map(stateId => `${stateId} License No.`).join(" / ")
        : label,
      dependencies: ["locations"],
      rules: [
        ({ getFieldValue }) => ({
          validator(_, value) {
            const locations = getFieldValue("locations") ?? [];
            const specialStates = locations.filter(isSpecialState);
            if (!specialStates.length) {
              if (!value) {
                return Promise.reject(
                  "Please enter a License Number or remove this field.",
                );
              }
              return Promise.resolve();
            }

            const pattern = getPattern();
            if (pattern && pattern.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject(
              `Per ${specialStates.join(
                "/",
              )} DOI, please enter this full text before your number: ${specialStates
                .map((stateId: any) => specialStateMessage[stateId])
                .join(" / ")}`,
            );
          },
        }),
      ],
    };
  }, [fieldName, getPattern, label, specialStates]);

  if (!isInputVisible)
    return (
      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={() => setIsInputVisible(true)}
      >
        Add License Number
      </Button>
    );

  return (
    <>
      <Form.Item
        name={fieldName}
        {...formProps}
        help={!specialStates.length ? undefined : helpMessage}
      >
        {specialStates?.length ? (
          <Input.TextArea
            placeholder={selectedStatesMessage}
            rows={specialStates.length}
          />
        ) : (
          <Input
            placeholder="License number"
            suffix={
              <DeleteOutlined
                onClick={() => {
                  setIsInputVisible(false);
                  onChange?.("");
                }}
              />
            }
          />
        )}
      </Form.Item>
    </>
  );
};
