import { Button, Drawer, Form, Space, Tabs } from "antd";
import { FormInstance } from "antd/lib/form";
import { useMemo, useState } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import TemplateRenderDataProvider from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import {
  MarketingMaterialPrintDeliveryForm,
  MarketingMaterialTableItem,
} from "shared/types/marketingMaterials";
import Preview from "../marketingMaterialDrawer/Preview";
import { useFetchMarketingMaterials } from "./hooks/useFetchMarketingMaterials";
import { useSelectedMarketingMaterial } from "./hooks/useSelectedMarketingMaterial";
import styles from "./PrintDrawer.module.scss";
import { PrintCheckout } from "./printDrawer/PrintCheckout";
import { PrintForm } from "./printDrawer/PrintForm";
import { ROUTES } from "./utils/constants";
import { useUser } from "shared/hooks/useUser";

export const PrintDrawer = () => {
  const [form] = Form.useForm();
  const { marketingMaterials } = useFetchMarketingMaterials();
  const { selectedIds: selectedMarketingMaterialsIds } =
    useDataListURLIds<MarketingMaterialTableItem>(getAllAdIds);
  const navigateWithSearch = useNavigateWithSearch();
  const user = useUser();

  const location = useLocation();
  const { materialId } = useParams();
  const onClose = () => {
    form.resetFields();
    setActiveMaterial(undefined);
    navigateWithSearch(materialId ? ROUTES.edit(materialId) : ROUTES.home);
  };

  const isSingleMaterial =
    !!materialId || selectedMarketingMaterialsIds.length === 1;

  const [activeMaterial, setActiveMaterial] = useState<string | undefined>(
    materialId ?? selectedMarketingMaterialsIds[0],
  );

  const isCurrentTabValid = async (
    marketingMaterialDeliveryData: MarketingMaterialPrintDeliveryForm,
    activeMaterialId: string,
  ) => {
    const activeTabData =
      marketingMaterialDeliveryData.materials[activeMaterialId];

    return (
      activeTabData.copies &&
      activeTabData.printMode &&
      Object.values(activeTabData.confirm).every(Boolean)
    );
  };

  const onNext = async (
    marketingMaterialDeliveryData: MarketingMaterialPrintDeliveryForm,
  ) => {
    const activeMaterialId =
      activeMaterial ?? materialId ?? selectedMarketingMaterialsIds[0];
    const isValid = await isCurrentTabValid(
      marketingMaterialDeliveryData,
      activeMaterialId,
    );
    if (!isValid) return;
    const activeMaterialIndex =
      selectedMarketingMaterialsIds.indexOf(activeMaterialId);

    const newActiveMaterial =
      selectedMarketingMaterialsIds[activeMaterialIndex + 1];

    if (!newActiveMaterial || materialId) {
      navigateWithSearch(ROUTES.printCheckout(activeMaterialId));
    }
    setActiveMaterial(newActiveMaterial);
  };

  const initialValues = useMemo(() => {
    const materialsIds = materialId
      ? [materialId]
      : selectedMarketingMaterialsIds;
    const materials = marketingMaterials.filter(({ id }) =>
      materialsIds.includes(id),
    );
    const { name, email, user_metadata } = user;
    return {
      materials: materials.reduce(
        (acc, { id, name, templateThumbnail, printOption }) => {
          return {
            ...acc,
            [id]: {
              id,
              name,
              templateThumbnail,
              copies: 0,
              amount: 0,
              printMode: printOption?.printMode[0],
              printOption: printOption,
            },
          };
        },
        {},
      ),
      shippingData: {
        fullName: name ?? "",
        email: email ?? "",
        phone: user_metadata?.phoneNumber ?? "",
        country: "United States",
      },
    };
  }, [materialId, selectedMarketingMaterialsIds, marketingMaterials, user]);

  const isPrintVisible = useMemo(
    () =>
      !!matchPath({ path: ROUTES.print(materialId) }, location.pathname) ||
      !!matchPath(
        { path: ROUTES.printCheckout(materialId) },
        location.pathname,
      ),
    [materialId, location.pathname],
  );

  return (
    <Drawer
      title="Order Prints"
      className={styles.drawer}
      width="calc(100vw - 48px)"
      visible={isPrintVisible}
      onClose={onClose}
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      footerStyle={{ textAlign: "right" }}
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={() => onNext(form.getFieldsValue())}>
            Next
          </Button>
        </Space>
      }
    >
      <Form<MarketingMaterialPrintDeliveryForm>
        form={form}
        layout="horizontal"
        labelAlign="left"
        className={styles.container}
        scrollToFirstError
        initialValues={initialValues}
      >
        {!!isSingleMaterial ? (
          <PrintDrawerContainer
            form={form}
            materialId={materialId ?? selectedMarketingMaterialsIds[0]}
          />
        ) : (
          <Tabs
            className={styles.tabs}
            activeKey={activeMaterial ?? selectedMarketingMaterialsIds[0]}
            onChange={key => setActiveMaterial(key)}
          >
            {selectedMarketingMaterialsIds.map(marketingMaterialId => {
              const material = marketingMaterials.find(
                ({ id }) => marketingMaterialId === id,
              );
              if (!material) return;
              return (
                <Tabs.TabPane key={marketingMaterialId} tab={material.name}>
                  <PrintDrawerContainer
                    form={form}
                    materialId={marketingMaterialId}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        )}
        <PrintCheckout form={form} />
      </Form>
    </Drawer>
  );
};

const PrintDrawerContainer = ({
  form,
  materialId,
}: {
  form: FormInstance<MarketingMaterialPrintDeliveryForm>;
  materialId: string;
}) => {
  const { material, templateFile } = useSelectedMarketingMaterial({
    materialId,
  });

  if (!material) {
    return null;
  }
  const { printOption } = material;
  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <TemplateRenderDataProvider file={templateFile} material={material}>
          <Preview selectedFile={templateFile} />
        </TemplateRenderDataProvider>
      </div>
      <Form.Item name={["materials", materialId]} style={{ overflow: "auto" }}>
        {printOption && (
          <PrintForm
            form={form}
            materialId={materialId}
            printOption={printOption}
          />
        )}
      </Form.Item>
    </div>
  );
};
