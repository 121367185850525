import { inRange } from "lodash";
import moment from "moment";
import { ArrayParam, StringParam } from "serialize-query-params";
import { Field } from "shared/components/dataListURL/types";
import { PrintOrderTableKeys } from "shared/types/salesEnablement/order";
import { ArchiveItem } from "shared/types/salesEnablementArchive";
import { formatDateValue } from "utils/helpers";
import { compareStringBy, onFilterBy } from "utils/helpers.table";

export type PrintArchiveField = Field<ArchiveItem>;

type Fields = Partial<Record<PrintOrderTableKeys, PrintArchiveField>>;

function isDateInRange(
  date: string | undefined,
  startDate: string,
  endDate: string,
) {
  const expirationDate = +moment(date);
  return inRange(expirationDate, +startDate, +endDate);
}

const getDeliveryDateFilterFn = (value: string[], record: ArchiveItem) => {
  const [dateVal] = value;
  if (!dateVal) return false;

  const [startDate, endDate] = dateVal.split(" ");

  return isDateInRange(record.deliveryDate, startDate, endDate);
};

export const printArchiveFields: Fields = {
  id: {
    filterFn: onFilterBy("id"),
    sorterFn: compareStringBy("id"),
    queryParamConfigFilter: StringParam,
  },
  deliveryDate: {
    filterFn: getDeliveryDateFilterFn,
    sorterFn: compareStringBy((archive: ArchiveItem) => {
      return archive.deliveryDate;
    }),
    queryParamConfigFilter: StringParam,
    defaultSortOrder: "descend",
    display: (value: string) =>
      value.split(" ").map(formatDateValue).join(" - "),
  },
  recipient: {
    filterFn: onFilterBy(
      (record: ArchiveItem) =>
        record.materialPrintOrderData?.shippingData?.fullName,
    ),
    sorterFn: compareStringBy(
      (record: ArchiveItem) =>
        record.materialPrintOrderData?.shippingData?.fullName,
    ),
    queryParamConfigFilter: StringParam,
  },
  estimatedAmount: {
    filterFn: onFilterBy("deliveryMethod"),
    sorterFn: compareStringBy("deliveryMethod"),
    queryParamConfigFilter: ArrayParam,
  },
  orderStatus: {
    filterFn: onFilterBy("orderStatus"),
    sorterFn: compareStringBy("orderStatus"),
    queryParamConfigFilter: ArrayParam,
  },
  completedBy: {
    filterFn: onFilterBy("completedBy"),
    sorterFn: compareStringBy("completedBy"),
    queryParamConfigFilter: ArrayParam,
  },
};

export const printArchiveFieldKeys = Object.keys(printArchiveFields) as Array<
  keyof typeof printArchiveFields
>;
