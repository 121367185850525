import { message } from "antd";
import { format, isWithinInterval } from "date-fns";
import { Invoice } from "shared/types/salesEnablement";

export const downloadInvoices = async (
  invoices: Invoice | Invoice[],
): Promise<void> => {
  const invoiceList = Array.isArray(invoices) ? invoices : [invoices];

  if (invoiceList.length === 0) {
    message.error("No invoice(s) selected");
    return;
  }

  try {
    for (const record of invoiceList) {
      if (record.zipFileUrl) {
        const response = await fetch(record.zipFileUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch file from URL: ${response.statusText}`,
          );
        }
        const blob = await response.blob();
        const fileName = `Invoice_${format(
          new Date(record.postDate),
          "MMMM_yyyy",
        )}.zip`;

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        message.error(`No zip file URL found for Invoice ${record.id}`);
      }
    }

    if (invoiceList.length > 1) {
      message.success(`File(s) successfully downloaded to your device.`);
    } else {
      message.success(`File successfully downloaded to your device.`);
    }
  } catch (error) {
    message.error("Error downloading invoice(s)");
  }
};

export const postDateFilterFn = (value: string, record: Invoice) => {
  const [dateVal] = value;
  if (dateVal) {
    const [startMillisStr, endMillisStr] = dateVal.split(" ");
    const startMillis = Number(startMillisStr);
    const endMillis = Number(endMillisStr);

    const recDate = new Date(record.postDate);
    return isWithinInterval(recDate, {
      start: new Date(startMillis),
      end: new Date(endMillis),
    });
  }

  return true;
};

export const amountFilterFn = (value: string, record: Invoice) => {
  const [minMax] = value;
  const [min, max] = minMax.split(" ");
  const minAmount = min ? Number(min) : null;
  const maxAmount = max ? Number(max) : null;

  if (minAmount !== null && maxAmount !== null) {
    return record.amount >= minAmount && record.amount <= maxAmount;
  } else if (minAmount !== null) {
    return record.amount >= minAmount;
  } else if (maxAmount !== null) {
    return record.amount <= maxAmount;
  }

  return true;
};
