import {
  CalendarOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Badge, Tooltip } from "antd";
import { uniq, upperCase } from "lodash";
import moment from "moment-timezone";
import { useMemo } from "react";
import { TIMEZONE } from "screens/designStudio/fields";
import Tags from "screens/designStudio/shared/Tags";
import {
  customizableMap,
  fields,
} from "screens/designStudio/templates/fileDrawer/FormFields.schema";
import { deliveryMethods } from "screens/designStudio/utils";
import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import NameCardCell from "shared/components/NameCardCell";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { TimeAgo } from "shared/components/TimeAgo";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { StateKey } from "shared/constants/states";
import { useIsPrintEnabled } from "shared/hooks/salesEnablement/useIsPrintEnabled";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import type { Language } from "shared/types/salesEnablement";
import {
  audienceFilterOptions,
  Languages,
} from "utils/helpers.salesEnablement";
import { getFiltersFromData } from "utils/helpers.table";
import EmptyMarketingMaterials from "./EmptyMarketingMaterials";
import { useMarketingMaterialActions } from "./hooks/useMarketingMaterialActions";
import { marketingMaterialFieldsKeys } from "./marketingMaterialsTable/fields";
import { disabledPrintDeliveryMessage, ROUTES } from "./utils/constants";
import { getMaterialBadge } from "./utils/getMarketingMaterialBadge";

type Props = {
  data: MarketingMaterialTableItem[];
  originalData: MarketingMaterialTableItem[];
  loading: boolean;
  isPdfLoading: boolean;
  onEmail: (selectedItems: MarketingMaterialTableItem[]) => void;
  onDownload: (selectedItems: MarketingMaterialTableItem[]) => void;
  onPrint: (selectedItems: MarketingMaterialTableItem[]) => void;
};

const MarketingMaterialsTable = ({
  data,
  loading,
  originalData,
  isPdfLoading,
  onEmail,
  onDownload,
  onPrint,
}: Props) => {
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const { windowInnerHeight } = useWindowSize();
  const navigateWithSearch = useNavigateWithSearch();
  const { onDuplicate, onDelete, isCreating, isDeleting } =
    useMarketingMaterialActions();

  const isPrintEnabled = useIsPrintEnabled();

  const filtersData = useMemo(() => {
    const { locationsSet, audienceSet } = originalData.reduce(
      (acc, item) => {
        item.locations?.forEach(state => acc.locationsSet.add(state));
        item.templateAudience?.forEach(audience =>
          acc.audienceSet.add(audience),
        );
        return acc;
      },
      {
        locationsSet: new Set<StateKey>(),
        audienceSet: new Set<string>(),
      },
    );

    return {
      locations: [...locationsSet].sort(),
      audience: [...audienceSet].sort(),
    };
  }, [originalData]);

  const getFilters = getFiltersFromData(originalData);

  const columns: ITableColumnURL<
    keyof MarketingMaterialTableItem,
    MarketingMaterialTableItem
  >[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 280,
      filterDropdown: SearchFilterDropdown,
      filterIcon: <SearchOutlined />,
      render: (_, materialItem) => {
        return (
          <NameCardCell
            name={materialItem.name}
            thumbnail={materialItem.templateThumbnail}
            onCellClick={() => navigateWithSearch(materialItem.id)}
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      key: "materialStatus",
      render: (_text: string, record: MarketingMaterialTableItem) => {
        const materialStatus = getMaterialBadge(record);
        return (
          <Tooltip title={materialStatus.tooltipInfo}>
            <Badge color={materialStatus.color} text={materialStatus.title} />
          </Tooltip>
        );
      },
    },
    {
      key: "templateDeliveryMethods",
      title: "Delivery Method",
      width: 200,
      dataIndex: "deliveryMethods",
      filters: deliveryMethods.map(method => ({
        text: method.label,
        value: method.value,
      })),
      render: (_, material) => {
        return (
          <Tags
            tags={
              uniq(material?.templateDeliveryMethods)?.map(item => ({
                id: item + material.id,
                name:
                  deliveryMethods.find(opt => opt.value === item)?.label ||
                  item,
              })) ?? []
            }
          />
        );
      },
    },
    {
      title: "States",
      dataIndex: "locations",
      key: "locations",
      width: 200,
      filters: filtersData.locations.map(state => ({
        text: state,
        value: state,
      })),
      render: (_, material) => (
        <Tags
          tags={material.locations?.map(state => ({
            id: state,
            name: state,
          }))}
        />
      ),
    },
    {
      key: "templateCustomizable",
      title: "Customizable",
      dataIndex: "templateCustomizable",
      width: 200,
      filters: fields.customizable.options.map(item => ({
        text: item.label,
        value: item.value,
      })),
      render: (_, material) =>
        material.templateCustomizable
          ? customizableMap.customizable
          : customizableMap.nonCustomizable,
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "templateAudience",
      width: 200,
      filters: audienceFilterOptions.map(option => ({
        text: option.label,
        value: option.value,
      })),
      render: (_, material) => (
        <Tags
          tags={material.templateAudience?.map(item => ({
            id: item,
            name:
              audienceFilterOptions.find(opt => opt.value === item)?.label ||
              item,
          }))}
        />
      ),
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      width: 150,
      render: upperCase,
      filters: getFilters("language", {
        textMapper: (value: string) => Languages[value as Language].label,
        sorted: true,
      }),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (date: Date) => <TimeAgo date={date} />,
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      title: "Last Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 150,
      render: (date: Date) => <TimeAgo date={date} />,
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    ...(isSalesEnablementAdmin
      ? [
          {
            title: "Expiration Date",
            dataIndex: "templateExpirationDate",
            key: "templateExpirationDate",
            width: 150,
            render: (_, material) =>
              moment(material.templateExpirationDate)
                .tz(TIMEZONE)
                .format("MM/YY"),
            filterDropdown: DateFilterDropdown,
            filterIcon: (filtered: boolean) => (
              <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
            ),
          } as ITableColumnURL<
            keyof MarketingMaterialTableItem,
            MarketingMaterialTableItem
          >,
        ]
      : []),
  ];

  const generateToolbarContents: (
    record?: MarketingMaterialTableItem,
  ) => ToolbarButton = record => {
    if (!record) return {};
    const disabledOrderMessage =
      record.templateErrorStatus?.message ??
      record.materialErrorStatus?.message;
    return {
      Edit: {
        icon: <EditOutlined />,
        onClick: () => {
          navigateWithSearch(ROUTES.edit(record.id));
        },
        showInContextMenu: true,
        showInInlineMenu: true,
      },
      Delete: {
        icon: <DeleteOutlined />,
        onClick: () => {
          onDelete({
            ids: [record.id],
            mode: "optimistic",
          });
        },
        showInContextMenu: true,
        showInInlineMenu: true,
        loading: isDeleting,
      },
      Duplicate: {
        icon: <CopyOutlined />,
        onClick: () => {
          onDuplicate({
            material: record,
            mode: "optimistic",
          });
        },
        showInContextMenu: true,
        showInInlineMenu: true,
        disabled: !!record.templateErrorStatus,
        loading: isCreating,
        extraInfo: {
          tooltip: record.templateErrorStatus?.message ?? "Duplicate",
        },
      },
      DownloadPDF: {
        showInContextMenu: true,
        showInInlineMenu: true,
        disabled:
          !record?.templateDeliveryMethods?.includes("download") ||
          !!disabledOrderMessage ||
          isPdfLoading,
        onClick: () => {
          onDownload([record]);
        },
        icon: <DownloadOutlined />,
        extraInfo: {
          title: "Download",
          tooltipText: disabledOrderMessage ?? "Download",
        },
      },
      CustomButton: {
        showInContextMenu: true,
        showInInlineMenu: true,
        onClick: () => {
          onEmail([record]);
        },
        icon: <MailOutlined />,
        disabled:
          !record?.templateDeliveryMethods?.includes("email") ||
          !!disabledOrderMessage ||
          !isSalesEnablementAdmin,
        extraInfo: {
          title: "Email",
          text: "Email",
          tooltipText: disabledOrderMessage ?? "Email",
          icon: <MailOutlined />,
        },
      },
      Print: {
        showInContextMenu: true,
        showInInlineMenu: true,
        icon: <PrinterOutlined />,
        disabled:
          !isPrintEnabled || record?.templateDeliveryMethods?.includes("email"),
        onClick: () => {
          onPrint([record]);
        },
        extraInfo: {
          title: "Print",
          text: "Print",
          tooltipText: !isPrintEnabled && disabledPrintDeliveryMessage,
          tooltipPlacement: "topRight",
        },
      },
    };
  };

  return (
    <TableActionsContextProvider<MarketingMaterialTableItem>
      renderContextActions={record => (
        <ContextTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
      renderHoverActions={record => (
        <InlineTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
    >
      <DataListURLTable<
        keyof MarketingMaterialTableItem,
        MarketingMaterialTableItem
      >
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: <EmptyMarketingMaterials />,
        }}
        size="small"
        scroll={{ y: windowInnerHeight - 245 }}
        type="hoverActionsVirtualTable"
        fieldKeys={marketingMaterialFieldsKeys}
      />
    </TableActionsContextProvider>
  );
};

export default MarketingMaterialsTable;
