import { useMemo, useState } from "react";
import { Button, Drawer, Checkbox, Input, Tooltip, Form, Modal } from "antd";
import { SelectFilter } from "shared/components/salesEnablement/filter/SelectFilter";
import { SelectCheckFilter } from "shared/components/salesEnablement/filter/SelectCheckFilter";
import { DateRangeFilter } from "shared/components/salesEnablement/filter/DateRangeFilter";
import { RangeFilter } from "shared/components/salesEnablement/filter/RangeFilter";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import uuid from "uuid";
import styles from "./FilterDrawer.module.scss";
import { spreadCSV } from "utils/helpers.array";
import { useUser } from "shared/hooks/useUser";
import {
  FilterFields,
  isCheckFilterField,
  isDateFilterField,
  isSelectFilterField,
  deepFilterOption,
  useFilterFields,
  SelectFilterField,
  isRangeFilterField,
} from "./useFilterFields";
import useFilterPresets, {
  Feature,
  FilterPreset,
} from "screens/designStudio/DrawerContext/useFilterPresets";
import moment from "moment-timezone";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { salesEnablementTheme } from "theme/salesEnablement";
import { getOptionsWithCount } from "./utils";

type Props<T> = {
  onClose: () => void;
  open: boolean;
  data: T[];
  fields: FilterFields<T>;
  filterKey: Feature;
};

const FilterDrawer = <T extends Record<string, unknown>>(props: Props<T>) => {
  const [form] = Form.useForm<{ presetName: string }>();
  const { onClose, open, data, fields, filterKey } = props;
  const [saveFilter, setSaveFilter] = useState<boolean>(false);
  const isAdmin = useIsSalesEnablementAdmin();

  const {
    filterFields,
    prefilteredItems,
    dataFilters,
    setDataFilters,
    updateDataFilter,
    setFilters,
    resetFilters,
  } = useFilterFields({ data, filterFields: fields });

  const { sub: userId } = useUser();

  const {
    presets,
    loading,
    deletePreset,
    selectedPreset,
    setSelectedPreset,
    createPreset,
  } = useFilterPresets(filterKey);

  const presetNameInitialValue = useMemo(() => {
    const regex = /^New Filter Selection (\d+)$/;
    const nextNumber = presets
      .map(preset => {
        const match = preset.name.match(regex);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter(num => num !== null)
      .sort((a, b) => a! - b!)
      .reduce<number>((acc, num) => (num === acc ? acc + 1 : acc), 1);

    form.setFieldsValue({ presetName: `New Filter Selection ${nextNumber}` });

    return `New Filter Selection ${nextNumber}`;
  }, [presets, form]);

  const onPresetClear = () => {
    setSelectedPreset(undefined);
    setDataFilters({});
  };

  const closeDrawer = () => {
    setSaveFilter(false);
    setSelectedPreset(undefined);
    form.resetFields();
    onClose();
  };

  const onDeleteSuccess = (name: string) => {
    successNotification({
      messageLabel: (
        <span>
          The Filter <strong>&apos;{name}&apos;</strong> has been successfully
          deleted
        </span>
      ),
      size: "big",
    });
  };

  const onDeleteError = () => {
    errorNotification({
      messageLabel: (
        <span>There was an error and the Filter could not be deleted</span>
      ),
      size: "big",
    });
  };

  const onSaveSuccess = (name: string) => {
    successNotification({
      messageLabel: (
        <span>
          The Filter <strong>&apos;{name}&apos;</strong> has been successfully
          saved.
        </span>
      ),
      size: "big",
    });
  };

  const onSaveError = () => {
    errorNotification({
      messageLabel: (
        <span>There was an error and the Filter could not be saved</span>
      ),
      size: "big",
    });
  };

  const options = useMemo(
    () =>
      presets.map(({ id, name, feature }) => {
        const isSelected = selectedPreset?.name === name;
        return {
          key: `preset-option-${name}`,
          value: name,
          label: (
            <div
              className={classNames(
                styles.savedFilterOption,
                `${isSelected ? "selected" : ""}`,
              )}
            >
              <span>{name}</span>
              <span className={styles.deletePreset}>
                <Tooltip placement="topRight" title={`Delete saved filter`}>
                  <Button
                    className={styles.deleteButton}
                    type="text"
                    size="small"
                    icon={<DeleteOutlined className={styles.deleteIcon} />}
                    onClick={async e => {
                      e.preventDefault();
                      e.stopPropagation();
                      Modal.confirm({
                        title: (
                          <b>
                            Are you sure you want to permanently delete this
                            saved filter?
                          </b>
                        ),
                        icon: (
                          <ExclamationCircleOutlined
                            style={{
                              color:
                                salesEnablementTheme?.primaryColor ?? "#0070A9",
                            }}
                          />
                        ),
                        content: "",
                        okText: "Delete",
                        cancelText: "Cancel",
                        okButtonProps: { type: "primary", danger: true },
                        onOk: () => {
                          deletePreset(
                            { id, feature },
                            {
                              onSuccess: () => {
                                setSelectedPreset(undefined);
                                onDeleteSuccess(name);
                              },
                              onError: onDeleteError,
                            },
                          );
                        },
                      });
                    }}
                  />
                </Tooltip>
              </span>
            </div>
          ),
        };
      }),
    [presets, selectedPreset?.name, deletePreset, setSelectedPreset],
  );

  const countEnabledFilterFields = useMemo(() => {
    return filterFields.map(field => {
      return {
        ...field,
        options: getOptionsWithCount(
          data,
          prefilteredItems,
          field as SelectFilterField<T>,
          dataFilters,
        ),
      };
    });
  }, [data, prefilteredItems, filterFields, dataFilters]);

  return (
    <Drawer
      title="Filters"
      placement="right"
      onClose={onClose}
      closeIcon={null}
      visible={open}
      width="464px"
      bodyStyle={{ padding: 0 }}
      footer={
        <>
          <div>
            <Button
              danger
              onClick={() => {
                resetFilters();
                onPresetClear();
              }}
            >
              Clear Filters
            </Button>
          </div>
          <div className={styles.footer}>
            <Button onClick={closeDrawer}>Cancel</Button>
            <Button
              type="primary"
              disabled={false}
              onClick={async () => {
                if (saveFilter) {
                  const messageLabel = await form
                    .validateFields(["presetName"])
                    .then(() => null)
                    .catch(err => err.errorFields?.[0]?.errors?.[0]);

                  if (messageLabel) {
                    return errorNotification({
                      messageLabel,
                      size: "big",
                    });
                  }

                  const preset: FilterPreset = {
                    name: form.getFieldValue("presetName"),
                    userId: userId,
                    id: uuid.v4(),
                    filters: dataFilters,
                    feature: filterKey,
                  };
                  createPreset(preset, {
                    onSuccess: () => onSaveSuccess(preset.name),
                    onError: onSaveError,
                  });
                }
                setFilters(dataFilters);
                form.resetFields();
                closeDrawer();
              }}
            >
              {`Show ${prefilteredItems.length} Results`}
            </Button>
          </div>
        </>
      }
      footerStyle={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.topbox}>
            <SelectFilter<string>
              disabled={loading}
              loading={loading}
              value={selectedPreset?.name}
              key="saved-filters"
              title="My Saved Filters"
              onSelect={(value: string) => {
                const preset = presets.find(preset => preset.name === value);
                if (!preset) return;

                setSelectedPreset(preset);
                const { filters } = preset;
                if (!filters) return;

                setDataFilters(filters);
              }}
              onClear={onPresetClear}
              options={options}
            />
          </div>

          {countEnabledFilterFields.map(filter => {
            if (isDateFilterField(filter)) {
              return (
                <DateRangeFilter
                  key={filter.key}
                  title={filter.title}
                  value={
                    filter.value
                      ? [moment(filter.value?.[0]), moment(filter.value?.[1])]
                      : [null, null]
                  }
                  onChange={(value: string[]) => {
                    setSelectedPreset(undefined);
                    updateDataFilter(filter.key, value);
                  }}
                />
              );
            }

            if (isRangeFilterField(filter)) {
              return (
                <RangeFilter
                  key={filter.key}
                  title={filter.title}
                  value={
                    filter.value
                      ? [
                          filter.value[0] != null
                            ? filter.value[0].toString()
                            : null,
                          filter.value[1] != null
                            ? filter.value[1].toString()
                            : null,
                        ]
                      : [null, null]
                  }
                  onChange={value => {
                    setSelectedPreset(undefined);
                    const stringValues = value.map(v => v ?? "");
                    const cleaned = stringValues.filter(Boolean);
                    updateDataFilter(
                      filter.key,
                      cleaned.length ? [cleaned.join(" ")] : [],
                    );
                  }}
                />
              );
            }

            if (
              isCheckFilterField(filter) &&
              (isAdmin || ["metrics"].includes(filter.key))
            ) {
              return (
                <Checkbox
                  key={filter.key}
                  checked={!!filter.value?.length}
                  className={styles.checkbox}
                  onChange={e => {
                    setSelectedPreset(undefined);
                    if (filter.key === "createdBy") {
                      updateDataFilter(
                        filter.key,
                        e.target.checked ? [userId] : [],
                      );
                    }
                    if (filter.key === "metrics") {
                      updateDataFilter(
                        filter.key,
                        e.target.checked ? ["popular"] : [],
                      );
                    }
                  }}
                >
                  <span>{filter.title}</span>
                  {filter.tooltip && (
                    <Tooltip
                      overlayStyle={{ maxWidth: "unset" }}
                      title={filter.tooltip}
                    >
                      <span className={styles.tooltipSpan}>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  )}
                </Checkbox>
              );
            }
            if (isSelectFilterField(filter)) {
              return (
                <SelectCheckFilter
                  filterOption={deepFilterOption}
                  mode={"multiple"}
                  key={filter.key}
                  title={filter.title}
                  value={spreadCSV(filter.value)}
                  options={filter.options}
                  onChange={(value: string[]) => {
                    {
                      setSelectedPreset(undefined);
                      updateDataFilter(filter.key, value);
                    }
                  }}
                />
              );
            }
          })}
        </div>
        <div className={styles.save}>
          <div className={styles.bottombox}>
            <Form form={form}>
              {(_, instance) => (
                <>
                  <Form.Item className={styles.saveFilterCheckbox}>
                    <Checkbox
                      checked={saveFilter}
                      onChange={e => setSaveFilter(e.target.checked)}
                    >
                      Save filter selection
                    </Checkbox>
                  </Form.Item>
                  {saveFilter && (
                    <Form.Item
                      className={styles.saveFilterInput}
                      name="presetName"
                      initialValue={presetNameInitialValue}
                      help={
                        instance.getFieldError("presetName").length > 0 ? (
                          <div className={styles.error}>
                            {instance.getFieldError("presetName").map(e => e)}
                          </div>
                        ) : (
                          <div>
                            Add new filter name and click ’Show Results’ below
                            to save.
                          </div>
                        )
                      }
                      rules={[
                        {
                          required: saveFilter,
                          message:
                            "Add new filter name and click ’Show Results’ below to save.",
                        },
                        {
                          validator: async (_, value) => {
                            if (presets.some(preset => preset.name === value)) {
                              return Promise.reject(
                                new Error("Preset name not unique"),
                              );
                            }
                          },
                          message:
                            "A filter with the entered name already exists. Please make sure the filter name is unique",
                        },
                      ]}
                    >
                      <Input placeholder="Name new filter selection" />
                    </Form.Item>
                  )}
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
