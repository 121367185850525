import { Button, Tooltip } from "antd";
import { getNextButtonTitle } from "./utils/getNextButtonTitle";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useAdLibraryExportContext } from "../adLibrary/AdLibraryExportContext";
import { usePortalExportContext } from "../portal/PortalExportContext";
import { useColumnValidation } from "../portal/useColumnValidation";
import { isNextButtonDisabled } from "./utils/isNextButtonDisabled";
import { getNextButtonText } from "./utils/getNextButtonText";
import { useMetadataValidation } from "../portal/useMetadataValidation";
import { useValidateAssetNames } from "./useValidateAssetNames";
import { useMemo } from "react";

export const FooterNextButton = ({
  handleNextButtonClick,
  currentStep,
  isStartingExport,
}: {
  handleNextButtonClick: () => void;
  currentStep: number;
  isStartingExport: boolean;
}) => {
  const { selectedAssetBatches } = useFeedContext();
  const {
    exportDestination,
    adType,
    validity: adLibraryValidity,
  } = useAdLibraryExportContext();
  const { formValues } = usePortalExportContext();
  const { validateBrandsColumnQuery, validateAccountsColumnQuery } =
    useColumnValidation();
  const validateAssetNamesQuery = useValidateAssetNames();
  const validateMetadataQuery = useMetadataValidation();
  const isBrandColumnValid = validateBrandsColumnQuery.data === true;
  const isAccountColumnValid = validateAccountsColumnQuery.data === true;
  const isMetadataValid = validateMetadataQuery.data !== false;
  const areAssetNamesValid = validateAssetNamesQuery.data !== false;
  const isLoading =
    validateMetadataQuery.isLoading ||
    validateBrandsColumnQuery.isLoading ||
    validateAccountsColumnQuery.isLoading ||
    validateAssetNamesQuery.isLoading;

  const { brandId, accountId } = formValues;

  const tooltipTitle = useMemo(() => {
    if (validateMetadataQuery.data === false) {
      return "The selected feed columns exceed the maximum data limit. Please select a smaller set of columns.";
    }
    if (validateAssetNamesQuery.data === false) {
      return 'One or more asset names contain a "/" character. Please review the Rename step in the selected asset batches.';
    }
    return "";
  }, [validateAssetNamesQuery.data, validateMetadataQuery.data]);

  return (
    <Tooltip title={tooltipTitle} placement="topLeft">
      <Button
        onClick={handleNextButtonClick}
        type="primary"
        disabled={isNextButtonDisabled({
          currentStep,
          exportDestination,
          adType,
          brandId,
          accountId,
          selectedAssetBatches,
          adLibraryValidity,
          isBrandColumnValid,
          isAccountColumnValid,
          isMetadataValid,
          areAssetNamesValid,
        })}
        loading={isStartingExport || isLoading}
        title={getNextButtonTitle(
          exportDestination,
          selectedAssetBatches,
          currentStep,
        )}
      >
        {getNextButtonText(exportDestination, currentStep)}
      </Button>
    </Tooltip>
  );
};
