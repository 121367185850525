import CommonFilterDrawer from "shared/components/filterDrawer/FilterDrawer";
import { FilterFields } from "shared/components/filterDrawer/useFilterFields";
import { Invoice } from "shared/types/salesEnablement";

type InvoiceFilterDrawerProps = {
  invoiceTableData: Invoice[];
  open: boolean;
  onClose: () => void;
};

const InvoiceFilterDrawer = ({
  invoiceTableData,
  open,
  onClose,
}: InvoiceFilterDrawerProps) => {
  const filterFields: FilterFields<Partial<Invoice>> = [
    {
      title: "Amount",
      key: "amount",
      type: "range",
    },
    {
      title: "Doc Date",
      key: "postDate",
      type: "date",
    },
  ];

  return (
    <CommonFilterDrawer
      data={invoiceTableData}
      filterKey="salesenablement-invoices"
      fields={filterFields}
      open={open}
      onClose={onClose}
    />
  );
};

export default InvoiceFilterDrawer;
