import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { ArchiveTableItem } from "shared/types/salesEnablementArchive";
import {
  ArchiveHistoryFields,
  archiveHistoryFieldKeys,
} from "./archiveHistoryTable/fields";
import styles from "./ArchiveHistoryTable.module.scss";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Typography } from "antd";
import { getColumns } from "./archiveHistoryTable/columns";
import { RightOutlined } from "@ant-design/icons";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";

type Props = {
  data: ArchiveTableItem[];
  loading: boolean;
  onTemplateClick: (element: ArchiveTableItem) => void;
  onMaterialClick: (element: ArchiveTableItem) => void;
  viewType: "historyLog" | "templates";
  setViewType: Dispatch<SetStateAction<"historyLog" | "templates">>;
};

const ArchiveTable = ({
  data,
  loading,
  onTemplateClick,
  onMaterialClick,
  viewType,
  setViewType,
}: Props) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const columns = useMemo(
    () =>
      getColumns({
        tableType: viewType,
        onMaterialClick,
        onTemplateClick,
        isSalesEnablementAdmin,
      }),
    [viewType, onMaterialClick, onTemplateClick, isSalesEnablementAdmin],
  );

  return (
    <div>
      <div className={styles.toggleContainer}>
        <Typography.Text
          className={viewType === "historyLog" ? styles.selected : undefined}
          onClick={() => setViewType("historyLog")}
        >
          History Log
        </Typography.Text>
        <Typography.Text
          className={viewType === "templates" ? styles.selected : undefined}
          onClick={() => setViewType("templates")}
        >
          Templates
        </Typography.Text>
      </div>
      <DataListURLTable<
        ArchiveHistoryFields,
        Partial<ArchiveTableItem> & { id: string }
      >
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: windowInnerHeight - 265, x: windowInnerWidth - 20 }}
        type="virtual"
        fieldKeys={archiveHistoryFieldKeys}
        expandable={{
          expandedRowRender: record => {
            return record.archives ? (
              <NestedArchiveHistoryTable
                data={record.archives}
                tableType={viewType}
                onMaterialClick={onMaterialClick}
                onTemplateClick={onTemplateClick}
              />
            ) : null;
          },
          rowExpandable: record => !!record.archives?.length,
          expandIcon: ({ expanded, onExpand, record }) =>
            !!record.archives?.length ? (
              <RightOutlined
                style={{
                  transition: "all 0.1s ease-in-out",
                  transform: `${
                    expanded ? "rotate(90deg)" : "rotate(0deg)"
                  } scale(0.8)`,
                }}
                onClick={e => onExpand(record, e)}
              />
            ) : null,
        }}
      />
    </div>
  );
};

const NestedArchiveHistoryTable = ({
  data,
  tableType,
  onMaterialClick,
  onTemplateClick,
}: {
  data: ArchiveTableItem[];
  tableType: "historyLog" | "templates";
  onMaterialClick: (record: ArchiveTableItem) => void;
  onTemplateClick: (record: ArchiveTableItem) => void;
}) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const columns = useMemo(
    () =>
      getColumns({
        tableType,
        onMaterialClick,
        onTemplateClick,
        nested: true,
        isSalesEnablementAdmin,
      }),
    [tableType, onMaterialClick, onTemplateClick, isSalesEnablementAdmin],
  );

  const tableData = useDataListURLData<
    ArchiveHistoryFields,
    Partial<ArchiveTableItem> & { id: string }
  >({
    data,
    isLoading: false,
    isError: false,
  });

  return (
    <DataListURLTable<
      ArchiveHistoryFields,
      Partial<ArchiveTableItem> & { id: string }
    >
      type="normal"
      fieldKeys={archiveHistoryFieldKeys}
      className={styles.table}
      rowClassName={styles.row}
      dataSource={tableData.data}
      style={{ overflow: "hidden" }}
      columns={columns}
      scroll={{
        y: windowInnerHeight - 245,
        x: windowInnerWidth - 20,
      }}
      disableRowSelection
      showHeader={false}
      pagination={false}
      size="small"
    />
  );
};

export default ArchiveTable;
