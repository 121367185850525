import { groupBy } from "lodash";
import { Moment } from "moment";
import { useMemo } from "react";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import {
  isAccountGroupLayer,
  isProductGroupLayer,
} from "screens/designStudio/templates/fileDrawer/utils";
import APIs from "services";
import { ProductField } from "shared/types/marketingMaterials";
import {
  ArchiveItem,
  ArchiveTableItem,
} from "shared/types/salesEnablementArchive";
import { dedupe, isTruthy, nonNullable } from "utils/helpers.array";
import { getPageLayers } from "utils/helpers.salesEnablement";
import { trimAllSpaces } from "utils/helpers.string";
import { useInfiniteFetchAll } from "../useInfiniteFetchAll";
import { useFetchPrintOrdersArchives } from "./useFetchPrintOrdersArchives";

type Response<T> = {
  result: T;
  error?: Error;
};

export const useFetchArchives = ({
  enabled = true,
  startDate,
  endDate,
}: {
  enabled?: boolean;
  startDate?: Moment | null;
  endDate?: Moment | null;
} = {}) => {
  const start = startDate ? startDate.toISOString() : undefined;
  const end = endDate ? endDate.toISOString() : undefined;

  const { data, isLoading, isError } = useInfiniteFetchAll<
    Response<{ archives: ArchiveItem[]; paginationKey?: string }>,
    ArchiveItem
  >({
    queryKey: [QUERY_KEYS.deliveries, start, end],
    queryFn: ({ paginationKey }) =>
      APIs.services.salesEnablement.getArchives(paginationKey, start, end),
    getDataFromResponse: response => response.result.archives,
    enabled,
    getPaginationKey: response =>
      response.result.paginationKey ? response.result.paginationKey : undefined,
  });

  return { data, isLoading, isError };
};

export const useFetchArchivesWithDetails = ({
  startDate,
  endDate,
}: {
  startDate?: Moment | null;
  endDate?: Moment | null;
} = {}): {
  archives: ArchiveTableItem[];
  archivesByTemplate: ArchiveTableItem[];
  originalData: ArchiveItem[] | undefined;
  isLoading: boolean;
} => {
  const { data, isLoading } = useFetchArchives({
    startDate,
    endDate,
  });
  const { data: printOrders } = useFetchPrintOrdersArchives();

  const printArchivesPerOrder = groupBy(printOrders?.archives, "printOrderId");
  const nestedPrintArchives = useMemo(() => {
    if (!printOrders || !data) return [];
    return printOrders?.orders?.flatMap(printOrder => {
      const orderItems = printArchivesPerOrder[printOrder.id];
      if (!orderItems || orderItems.length < 2) return [];
      const printArchives = orderItems.flatMap(orderItem => {
        return mapArchiveItemToArchiveTableItem(orderItem) ?? [];
      });

      return {
        id: printOrder.id,
        archives: printArchives,
        deliveryMethods: ["print"],
        orderStatus: printOrder.orderStatus,
        estimatedAmount: printOrder.totalAmount,
        agentId: printOrder.createdBy.split("|")[1],
        agent: printOrder.createdBy,
        deliveryDate: printOrder.createdAt,
      };
    });
  }, [data, printArchivesPerOrder, printOrders]);

  const archives = useMemo(() => {
    if (!data || isLoading) return [];
    return [
      nestedPrintArchives,
      data
        .filter(
          archiveItem => !(printArchivesPerOrder[archiveItem.id]?.length > 1),
        )
        .map(archiveItem => mapArchiveItemToArchiveTableItem(archiveItem))
        .filter(isTruthy),
    ].flat();
  }, [data, isLoading, nestedPrintArchives, printArchivesPerOrder]);

  const archivesByTemplate = useMemo(() => {
    if (!data || isLoading) return [];

    const templatesMap = data.reduce<Record<string, ArchiveItem[]>>(
      (acc, item) => {
        if (item.templateData.id) {
          acc[item.templateData.id] = [
            ...(acc[item.templateData.id] || []),
            item,
          ];
        }
        return acc;
      },
      {},
    );

    return Object.keys(templatesMap).map<
      Partial<ArchiveTableItem> & { id: string }
    >(templateId => {
      const templateArchives = templatesMap[templateId];
      const deliveries = templateArchives.length;

      return {
        marketingMaterialName:
          templatesMap[templateId]?.[0].templateData.name ?? "",
        deliveries,
        templateId,
        archives: templateArchives
          .map(archive => mapArchiveItemToArchiveTableItem(archive))
          .filter(isTruthy),
        id: templateId,
        deliveryMethods: templateArchives
          .map(archive => archive.deliveryMethod)
          .filter(dedupe)
          .filter(nonNullable),
      };
    });
  }, [data, isLoading]);

  return {
    archives,
    archivesByTemplate,
    originalData: data,
    isLoading,
  };
};

const mapArchiveItemToArchiveTableItem = (
  archiveItem: ArchiveItem,
): ArchiveTableItem | undefined => {
  const { products, locations, account } = getMaterialFields(archiveItem);
  return {
    ...archiveItem,
    archiveMarketingMaterial: archiveItem.marketingMaterial,
    marketingMaterialName: archiveItem.marketingMaterial.name,
    templateName: archiveItem.templateData.name,
    templateId: archiveItem.templateData.id,
    type: "archive",
    products,
    account,
    locations,
    audience: archiveItem.templateData.audience,
    orderStatus: archiveItem.orderStatus ?? "COMPLETE",
    agent: archiveItem.agent,
    agentId: archiveItem.createdBy.split("|")[1],
    id: archiveItem.id,
    colType: archiveItem.templateData.materialType,
    language: archiveItem.marketingMaterial.language,
    deliveries: 0,
    deliveryMethods: [],
    archives: [],
  };
};

const getMaterialFields = (
  archive: ArchiveItem,
): {
  account?: string;
  products?: string[];
  locations: string[];
} => {
  const { marketingMaterial, templateData, renderVariables } = archive;
  if (!marketingMaterial.fields || !templateData || !renderVariables) {
    return {
      locations: marketingMaterial.locations,
    };
  }

  const templateFile = templateData.files[marketingMaterial.language];
  const materialFields = marketingMaterial.fields;

  const pageLayers = getPageLayers(templateFile);

  if (!pageLayers) {
    return {
      locations: marketingMaterial.locations,
    };
  }

  const fields = pageLayers.reduce<{
    products: string[];
    account?: string;
  }>(
    (accum, layer) => {
      if (isAccountGroupLayer(layer)) {
        const account = renderVariables[trimAllSpaces(layer.name)];
        if (!account || typeof account === "string") {
          accum.account = account;
        } else {
          accum.account = account.value;
        }
      }
      if (isProductGroupLayer(layer) && !!materialFields[layer.id]) {
        const product = materialFields[layer.id] as ProductField;
        accum.products = accum.products.concat(
          product.productsData.map(data => data.name ?? ""),
        );
      }
      return accum;
    },
    {
      account: undefined,
      products: [],
    },
  );

  return {
    account: fields.account,
    products: fields.products,
    locations: marketingMaterial.locations,
  };
};
