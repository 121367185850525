import { format } from "date-fns";
import { dateFilterToRange } from "screens/designStudio/helpers";
import { IDataListURLFields } from "shared/components/dataListURL/types";
import { Invoice } from "shared/types/salesEnablement";
import { StringParam } from "use-query-params";
import { compareStringBy } from "utils/helpers.table";
import { amountFilterFn, postDateFilterFn } from "./utils";

export const fields: IDataListURLFields<string, any> = {
  id: {
    filterFn: (value, record: Invoice) => record.id.includes(value),
    globalFilterFn: (value, record: Invoice) => {
      const searchValue = String(value ?? "").toLowerCase();
      return record.id.toLowerCase().includes(searchValue);
    },
    sorterFn: compareStringBy("id"),
    queryParamConfigFilter: StringParam,
  },
  amount: {
    filterFn: amountFilterFn,
    globalFilterFn: (value, record: Invoice) => {
      const searchValue = String(value ?? "");
      return record.amount.toFixed(2).includes(searchValue);
    },
    sorterFn: (a, b) => a.amount - b.amount,
    display: (minMax: string) => {
      const [min, max] = minMax.split(" ");
      return `Amount: $${min} - $${max}`;
    },
    queryParamConfigFilter: StringParam,
  },
  postDate: {
    filterFn: postDateFilterFn,
    globalFilterFn: (value, record: Invoice) => {
      const searchValue = String(value ?? "");
      return format(new Date(record.postDate), "MM/yyyy").includes(searchValue);
    },
    sorterFn: compareStringBy("postDate"),
    display: (fromTo: string) => {
      const formattedDates = dateFilterToRange([fromTo])?.map(date =>
        format(new Date(date.toDate()), "MM/dd/yyyy"),
      );
      if (!formattedDates) return "Doc. Date";
      return `Doc. Date: ${formattedDates[0]} - ${formattedDates[1]}`;
    },
    queryParamConfigFilter: StringParam,
  },
};
