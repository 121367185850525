import {
  PrintOptionItem,
  PrintMode,
  PrintOptionCopyType,
} from "shared/types/printOptions";

const getEntry = (printOption: PrintOptionItem, quantity: number) => {
  if (printOption.copySelection == PrintOptionCopyType.RANGE)
    return printOption.costPerCopy.find(item => {
      const min = item.quantity?.min || 0;
      const max = item.quantity?.max;
      return quantity >= min && (max ? quantity <= max : true);
    });
  return printOption?.costPerCopy.find(item => item.quantity === quantity);
};

export const getPriceByQuantity = (
  quantity: number,
  printOption: PrintOptionItem,
  printMode: PrintMode,
): number => {
  const entry = getEntry(printOption, quantity);
  const cost = entry?.cost?.[printMode] ?? 0;
  return quantity * cost;
};
