import { useCallback, useMemo } from "react";
import { useSyncedState } from "shared/hooks/useSyncedState";
import { isEqual } from "lodash";
import { Moment } from "moment-timezone";
import { useDataListURLFilters } from "../dataListURL/useDataListURLFilters";
import { useFilterDataByFilters } from "../dataListURL/useDataListURLData";
import { dateFilterToRange } from "screens/designStudio/helpers";
import { joinArray } from "utils/helpers.array";

type FilterField<T> = {
  title: string;
  key: Extract<keyof T, string>;
  type: "text" | "date" | "select" | "check" | "range";
  value?: [Moment, Moment] | string[] | [string | null, string | null];
  options?: {
    label: string;
    key: string;
    value: string;
  }[];
};

export type TextFilterField<T> = FilterField<T> & {
  value?: string[];
  type: "text";
};

export type CheckFilterField<T> = FilterField<T> & {
  value?: string[];
  type: "check";
  tooltip?: string;
};

export type RangeFilterField<T> = FilterField<T> & {
  value?: [number, number];
  type: "range";
};

export type DateFilterField<T> = FilterField<T> & {
  value?: [Moment, Moment];
  type: "date";
};

export type SelectFilterOption = {
  label: string;
  key: string;
  value: string;
  count?: number;
  disabled?: boolean;
};

export type SelectFilterField<T> = FilterField<T> & {
  value?: string[];
  type: "select";
  options: SelectFilterOption[];
};

export type FilterFields<T> = (
  | TextFilterField<T>
  | DateFilterField<T>
  | SelectFilterField<T>
  | CheckFilterField<T>
  | RangeFilterField<T>
)[];

type UseFilterFieldsProps<T extends Record<string, any>> = {
  filterFields: FilterFields<T>;
  data: T[];
};

export const useFilterFields = <T extends Record<string, any>>({
  filterFields,
  data,
}: UseFilterFieldsProps<T>) => {
  const { filters, resetFilters, setFilters } = useDataListURLFilters<
    string,
    T
  >();
  const [dataFilters, setDataFilters] = useSyncedState<typeof filters>(filters);

  const updateDataFilter = useCallback(
    (name: string, value: string[]) => {
      setDataFilters({
        ...dataFilters,
        [name]: value,
      });
    },
    [dataFilters, setDataFilters],
  );

  const prefilteredItems = useFilterDataByFilters<string, T>(data, dataFilters);

  const hasUncommittedFilters = !isEqual(filters, dataFilters);

  const fieldsWithValues: FilterField<T>[] = useMemo(() => {
    return filterFields.map(field => {
      if (field.type === "date") {
        return {
          ...field,
          value: dateFilterToRange(dataFilters[field.key]),
        };
      } else if (field.type === "range") {
        const storedVal = dataFilters[field.key] as string[] | undefined;
        if (storedVal && storedVal.length === 1) {
          const [startStr, endStr] = storedVal[0].split(" ");

          const startVal =
            startStr && !isNaN(parseFloat(startStr)) ? startStr : null;
          const endVal = endStr && !isNaN(parseFloat(endStr)) ? endStr : null;

          return {
            ...field,
            value: [startVal, endVal],
          };
        }

        return {
          ...field,
          value: [null, null],
        };
      }

      return {
        ...field,
        value: dataFilters[field.key],
      };
    });
  }, [dataFilters, filterFields]);

  return {
    filterFields: fieldsWithValues,
    prefilteredItems,
    dataFilters,
    setDataFilters,
    hasUncommittedFilters,
    updateDataFilter,
    filters,
    resetFilters,
    setFilters,
  };
};

export function isSelectFilterField<T>(
  field: FilterField<T>,
): field is SelectFilterField<T> {
  return field.type === "select";
}

export function isCheckFilterField<T>(
  field: FilterField<T>,
): field is CheckFilterField<T> {
  return field.type === "check";
}

export function isDateFilterField<T>(
  field: FilterField<T>,
): field is DateFilterField<T> {
  return field.type === "date";
}

export function isRangeFilterField<T>(
  field: FilterField<T>,
): field is RangeFilterField<T> {
  return field.type === "range";
}

export function deepFilterOption(
  input: string,
  option?: Record<string, string>,
) {
  if (!option) return false;

  return joinArray(Object.values(option), ` `)
    .toLowerCase()
    .includes(input.toLowerCase());
}
