import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Tabs } from "antd";
import { matchPath } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { useCreatePrintOption } from "shared/hooks/salesEnablement/useCreatePrintOption";
import { useUpdatePrintOption } from "shared/hooks/salesEnablement/useUpdatePrintOption";
import {
  PrintModeOption,
  PrintOptionCopyType,
  PrintOptionItem,
} from "shared/types/printOptions";
import { salesEnablementTheme } from "theme/salesEnablement";
import uuid, { v4 } from "uuid";
import { ROUTES } from "../constants";
import { AssociatedTemplates } from "./printOptionsForm/AssociatedTemplates";
import { PrintOptionsCostFieldTable } from "./printOptionsForm/PrintOptionsCostFieldTable";
import { PrintOptionsFields } from "./printOptionsForm/PrintOptionsFields";

export const PrintOptionsForm = ({
  formId,
  printOption,
  onClose,
}: {
  formId: string;
  printOption?: PrintOptionItem;
  onClose: () => void;
}) => {
  const onSuccess = (messageLabel: string) => {
    successNotification({
      messageLabel,
    });
    onClose();
  };
  const [form] = Form.useForm();
  const { mutate: createPrintOption } = useCreatePrintOption();
  const { mutate: updatePrintOption } = useUpdatePrintOption();

  const defaultValues: Partial<PrintOptionItem> = {
    printMode: [PrintModeOption.SIMPLEX],
    associatedTemplates: [],
    copySelection: PrintOptionCopyType.RANGE,
    productionTime: { min: 1 },
    costPerCopy: [
      {
        id: uuid.v4(),
        quantity: { min: 1 },
        cost: {
          [PrintModeOption.SIMPLEX]: undefined,
          [PrintModeOption.DUPLEX]: undefined,
        },
      },
    ],
  };

  const onFinish = async (printOptionFormValue: PrintOptionItem) => {
    const id = printOption?.id;

    if (id) {
      const titleText = "Are you sure you want to edit this print option?";
      const detailText =
        "Your edit will automatically update in agents' existing and in-progress Marketing Materials.";
      Modal.confirm({
        title: <b>{titleText}</b>,
        content: detailText,
        onOk: () => {
          const editedPrintOption = {
            id: printOption.id,
            option: {
              ...printOptionFormValue,
              unassociatedTemplates: printOption.associatedTemplates?.filter(
                templateId =>
                  !printOptionFormValue.associatedTemplates?.includes(
                    templateId,
                  ),
              ),
            },
          };
          updatePrintOption(editedPrintOption, {
            onSuccess: () =>
              onSuccess("The print option edits have been successfully saved."),
          });
        },
        icon: (
          <ExclamationCircleOutlined
            style={{ color: salesEnablementTheme?.primaryColor }}
          />
        ),
        okText: "Confirm",
      });
      return;
    }
    createPrintOption(
      { ...printOptionFormValue, id: v4() },
      {
        onSuccess: () =>
          onSuccess("The print option has been successfully saved."),
      },
    );
  };

  const isAssociatedTemplatesEnabled = !!matchPath(
    { path: ROUTES.edit(printOption?.id ?? "") },
    location.pathname,
  );

  const onFinishFailed = () => {
    errorNotification({
      messageLabel: "Please fix all errors before saving.",
    });
  };

  return (
    <Form
      id={formId}
      initialValues={printOption ?? defaultValues}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Tabs>
        <Tabs.TabPane tab="Settings" key="settings">
          <PrintOptionsFields form={form} />
          <PrintOptionsCostFieldTable />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Associated Templates"
          key="associatedTemplates"
          disabled={!isAssociatedTemplatesEnabled}
          forceRender
        >
          <Form.Item name="associatedTemplates" shouldUpdate>
            <AssociatedTemplates printOptionId={printOption?.id} />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};
